import BDNFT from './BDNFT'
import './App.css';

function App() {
  return (
    <div className="App">
    <BDNFT></BDNFT>
    </div>
  );
}

export default App;
